console.log("L18next.svelte :: START");
import i18next from 'i18next';

export function L18Key(s) {
    return i18next.t(s);
}

i18next.init({
  lng: 'en',
  debug: true,
  resources: {
    en: {
      translation: {
        "author": "Fausto Heikkinen (May-2020)",
        "ok": "ok",

        "John3_16_t": "God Loves You",
        "John3_16_v": "John 3:16 KJV",
        "John3_16_x": "For God so loved the world that he gave his one and only Son, that whoever believes in him shall not perish but have eternal life.",
        "John3_16_s": "https://www.bible.com/bible/111/JHN.3.16.kjv",

        "Jeremias_31_34_t": "I Will Forgive",
        "Jeremias_31_34_v": "Jeremiah 31:34 KJV",
        "Jeremias_31_34_x": "And they shall teach no more every man his neighbour, and every man his brother, saying, Know the Lord : for they shall all know me, from the least of them unto the greatest of them, saith the Lord : for I will forgive their iniquity, and I will remember their sin no more. ",
        "Jeremias_31_34_s": "https://my.bible.com/bible/1/JER.31.34",

        "Genesis_1_1_2_t": "In The Beginning",
        "Genesis_1_1_2_v": "Genesis 1:1-2 KJV",
        "Genesis_1_1_2_x": "In the beginning God created the heaven and the earth. And the earth was without form, and void; and darkness was upon the face of the deep. And the Spirit of God moved upon the face of the waters. ",
        "Genesis_1_1_2_s": "https://my.bible.com/bible/1/GEN.1.1-2",
        
        "PSalms_51_1_t": "Mercy",
        "PSalms_51_1_v": "PSalms 51:1 KJV",
        "PSalms_51_1_x": "Have mercy upon me, O God, according to thy lovingkindness: according unto the multitude of thy tender mercies blot out my transgressions.",
        "PSalms_51_1_s": "https://my.bible.com/bible/1/PSA.51.1",
        
        "PSalms_1_1_t": "Wisdom",
        "PSalms_1_1_v": "PSalms 1:1 KJV",
        "PSalms_1_1_x": "Blessed is the man that walketh not in the counsel of the ungodly, nor standeth in the way of sinners, nor sitteth in the seat of the scornful.",
        "PSalms_1_1_s": "https://my.bible.com/bible/1/PSA.1.1",
        
        "PSalms_1_2_t": "Wisdom Reward",
        "PSalms_1_2_v": "PSalms 1:2 KJV",
        "PSalms_1_2_x": "But his delight is in the law of the LORD; and in his law doth he meditate day and night.",
        "PSalms_1_2_s": "https://my.bible.com/bible/1/PSA.1.2",
        
      }
    },
    pt: {
      translation: {
        "author": "Fausto Heikkinen (May-2020)",
        "ok": "ok",

        "John3_16_t": "Deus Te Ama",
        "John3_16_v": "João 3:16 ARC",
        "John3_16_x": "Porque Deus amou o mundo de tal maneira que deu o seu Filho unigênito, para que todo aquele que nele crê não pereça, mas tenha a vida eterna.",
        "John3_16_s": "https://www.bible.com/pt/bible/129/JHN.3.16.ARC",

        "Jeremias_31_34_t": "Eu Perdoarei",
        "Jeremias_31_34_v": "Jeremias 31:34 ARC",
        "Jeremias_31_34_x": "E não ensinará alguém mais a seu próximo, nem alguém, a seu irmão, dizendo: Conhecei ao Senhor ; porque todos me conhecerão, desde o menor deles até ao maior, diz o Senhor ; porque perdoarei a sua maldade e nunca mais me lembrarei dos seus pecados.",
        "Jeremias_31_34_s": "https://my.bible.com/bible/212/JER.31.34",

        "Genesis_1_1_2_t": "No princípio",
        "Genesis_1_1_2_v": "Gênesis 1:1-2 ARC",
        "Genesis_1_1_2_x": "No princípio, criou Deus os céus e a terra. E a terra era sem forma e vazia; e havia trevas sobre a face do abismo; e o Espírito de Deus se movia sobre a face das águas.",
        "Genesis_1_1_2_s": "https://my.bible.com/bible/212/GEN.1.1-2",

        "PSalms_51_1_t": "Misericórdia",
        "PSalms_51_1_v": "Salmos 51:1 ARC",
        "PSalms_51_1_x": "Tem misericórdia de mim, ó Deus, segundo a tua benignidade; apaga as minhas transgressões, segundo a multidão das tuas misericórdias.",
        "PSalms_51_1_s": "https://my.bible.com/bible/212/PSA.51.1",
        
        "PSalms_1_1_t": "Sabedoria",
        "PSalms_1_1_v": "Salmos 1:1 ARC",
        "PSalms_1_1_x": "Bem-aventurado o varão que não anda segundo o conselho dos ímpios, nem se detém no caminho dos pecadores, nem se assenta na roda dos escarnecedores.",
        "PSalms_1_1_s": "https://my.bible.com/bible/212/PSA.1.1",
        
        "PSalms_1_2_t": "Sabedoria",
        "PSalms_1_2_v": "Salmos 1:2 ARC",
        "PSalms_1_2_x": "Antes, tem o seu prazer na lei do SENHOR, e na sua lei medita de dia e de noite.",
        "PSalms_1_2_s": "https://my.bible.com/bible/212/PSA.1.2",
        
        

      }
    },

  }
}, function(err, t) {
  // initialized and ready to go!
  console.log(i18next.t('ok'));
  console.log(i18next.languages);  

});
