/*
  main.js   This is the MAIN class initiated by package.json (best practices).
*/
console.log("main.js :: START");
import GlobalSass from './global.scss';
import Layout     from "./Layout.svelte";


const main_layout = new Layout({
  target: document.getElementById('editor1'),
});

