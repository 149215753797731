import {simplifyEvent} from '../ut/utils.js';

export function pannable(node) {
	let x;
	let y;
	
	function handleMousedown(event) {
		const se = simplifyEvent(event);
		x = se.x;
		y = se.y;
		// x = event.clientX;
		// y = event.clientY;

		node.dispatchEvent(new CustomEvent('panstart', {
			detail: { x, y }
		}));

		window.addEventListener('mousemove', handleMousemove);
		window.addEventListener('touchmove', handleMousemove);
		window.addEventListener('mouseup', handleMouseup);
		window.addEventListener('touchend', handleMouseup);
		window.addEventListener('touchcancel', handleMouseup);
	}

	function handleMousemove(event) {
		const se = simplifyEvent(event);
		const dx = se.x - x;
		const dy = se.y - y;
		x = se.x;
		y = se.y;
		// const dx = event.clientX - x;
		// const dy = event.clientY - y;
		// x = event.clientX;
		// y = event.clientY;

		node.dispatchEvent(new CustomEvent('panmove', {
			detail: { x, y, dx, dy }
		}));
	}

	function handleMouseup(event) {
		const se = simplifyEvent(event);
		x = se.x;
		y = se.y;

		node.dispatchEvent(new CustomEvent('panend', {
			detail: { x, y }
		}));

		window.removeEventListener('mousemove', handleMousemove);
		window.removeEventListener('touchmove', handleMousemove);
		window.removeEventListener('mouseup', handleMouseup);
		window.removeEventListener('touchend', handleMouseup);
		window.removeEventListener('touchcancel', handleMouseup);
	}

	node.addEventListener('mousedown', handleMousedown);
	node.addEventListener('touchstart', handleMousedown);

	return {
		destroy() {
			window.removeEventListener('mousemove', handleMousemove);
			window.removeEventListener('touchmove', handleMousemove);
			window.removeEventListener('mouseup', handleMouseup);
			window.removeEventListener('touchend', handleMouseup);
			window.removeEventListener('touchcancel', handleMouseup);

			node.removeEventListener('mousedown', handleMousedown);
			node.removeEventListener('touchstart', handleMousedown);
		}
	};
}