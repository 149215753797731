console.log('VersesDatasource.js :: START');

const Verses = {
    currentIdx: 0,
    days: [
        {
            key: 'John3_16',
        },
        {
            key: 'Jeremias_31_34',
        },
        {
            key: 'Genesis_1_1_2',
        },
        {
            key: 'PSalms_51_1',
        },
        {
            key: 'PSalms_1_1',
        },
        {
            key: 'PSalms_1_2',
        },
    ]

};

export function GetVerses(ForceRandom) {
    let old = Verses.currentIdx;
    do {
        Verses.currentIdx = Math.floor(Math.random() * Verses.days.length);
    } while (old == Verses.currentIdx);
    console.log('current idx: ' + Verses.currentIdx);
    const data = {
        title:      Verses.days[Verses.currentIdx].key.concat('_t'),
        verse:      Verses.days[Verses.currentIdx].key.concat('_v'),
        verseText:  Verses.days[Verses.currentIdx].key.concat('_x'),
        bibleSite:  Verses.days[Verses.currentIdx].key.concat('_s'),
    };
    console.log(data);
    return data;
}